/**
 * Need a bootstrap shell because of ModuleFederation
 * https://webpack.js.org/concepts/module-federation/#uncaught-error-shared-module-is-not-available-for-eager-consumption
 */
import('./bootstrap');

/**
 * To fix error
 * TS1208: 'bootstrap.tsx' cannot be compiled under '--isolatedModules' because it is considered a global script file.
 * Add an import, export, or an empty 'export {}' statement to make it a module.
 */
export {};
